var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.token
    ? _c("VTextField", {
        staticClass: "notranslate",
        staticStyle: { padding: "0" },
        attrs: {
          title: _vm.clipboardAvailable ? _vm.$tr("copyPrompt") : "",
          appendIcon: _vm.clipboardAvailable ? "content_copy" : null,
          readonly: "",
          color: "primary",
          hideDetails: true,
          "single-line": "",
          box: "",
          loading: _vm.loading,
        },
        on: {
          "click:append": function ($event) {
            $event.stopPropagation()
            return _vm.copyToken.apply(null, arguments)
          },
          click: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
          },
        },
        model: {
          value: _vm.displayToken,
          callback: function ($$v) {
            _vm.displayToken = $$v
          },
          expression: "displayToken",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }