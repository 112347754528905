var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VLayout",
    { staticClass: "my-4", attrs: { row: "", wrap: "" } },
    [
      _c(
        "VFlex",
        {
          attrs: {
            xs12: !_vm.printing,
            xs4: _vm.printing,
            sm5: !_vm.printing,
            md4: "",
            xl4: "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c(
                "label",
                {
                  staticClass: "body-1 font-weight-bold",
                  style: { color: _vm.$vuetify.theme.darkGrey },
                },
                [_vm._v("\n        " + _vm._s(_vm.label) + "\n      ")]
              ),
              _vm.definition
                ? _c("HelpTooltip", {
                    staticClass: "mx-1",
                    attrs: { text: _vm.definition, bottom: "" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "VFlex",
        {
          class: { notranslate: _vm.notranslate },
          attrs: {
            xs12: !_vm.printing,
            xs8: _vm.printing,
            sm7: !_vm.printing,
            md8: "",
            xl8: "",
          },
        },
        [
          _vm._t("default", function () {
            return [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }