var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.items.length
        ? [
            _vm.inline
              ? _c(
                  "div",
                  [
                    !_vm.printing
                      ? [
                          _c(
                            "ul",
                            {
                              staticClass: "inline-list",
                              class: { delimit: _vm.delimit },
                            },
                            [
                              _vm._l(
                                _vm.items.slice(0, _vm.maxItems),
                                function (item) {
                                  return _c(
                                    "li",
                                    { key: _vm.getKey(item) },
                                    [
                                      _vm._t(
                                        "item",
                                        function () {
                                          return [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(item) +
                                                "\n            "
                                            ),
                                          ]
                                        },
                                        { item: item }
                                      ),
                                    ],
                                    2
                                  )
                                }
                              ),
                              _vm._l(
                                _vm.items.slice(_vm.maxItems),
                                function (item) {
                                  return _c(
                                    "li",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isExpanded,
                                          expression: "isExpanded",
                                        },
                                      ],
                                      key: _vm.getKey(item),
                                    },
                                    [
                                      _vm._t(
                                        "item",
                                        function () {
                                          return [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(item) +
                                                "\n            "
                                            ),
                                          ]
                                        },
                                        { item: item }
                                      ),
                                    ],
                                    2
                                  )
                                }
                              ),
                              _vm.items.length > _vm.maxItems
                                ? _c(
                                    "li",
                                    [
                                      _c("ActionLink", {
                                        attrs: { text: _vm.toggleText },
                                        on: { click: _vm.toggle },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      : [_c("p", [_vm._v(_vm._s(_vm.items.join(", ")))])],
                  ],
                  2
                )
              : _c(
                  "div",
                  [
                    !_vm.printing
                      ? [
                          _vm._l(
                            _vm.items.slice(0, _vm.maxItems),
                            function (item) {
                              return _c(
                                "div",
                                { key: _vm.getKey(item) },
                                [
                                  _vm._t(
                                    "item",
                                    function () {
                                      return [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item) +
                                            "\n          "
                                        ),
                                      ]
                                    },
                                    { item: item }
                                  ),
                                ],
                                2
                              )
                            }
                          ),
                          _vm.items.length > _vm.maxItems
                            ? _c(
                                "VExpansionPanel",
                                { attrs: { value: _vm.isExpanded ? 0 : null } },
                                [
                                  _c(
                                    "VExpansionPanelContent",
                                    {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "span",
                                                  { on: { click: _vm.toggle } },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(_vm.toggleText) +
                                                        "\n              "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1030071498
                                      ),
                                    },
                                    _vm._l(
                                      _vm.items.slice(_vm.maxItems),
                                      function (item) {
                                        return _c(
                                          "div",
                                          { key: _vm.getKey(item) },
                                          [
                                            _vm._t(
                                              "item",
                                              function () {
                                                return [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(item) +
                                                      "\n              "
                                                  ),
                                                ]
                                              },
                                              { item: item }
                                            ),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._l(_vm.items, function (item) {
                          return _c("p", { key: _vm.getKey(item) }, [
                            _vm._v(
                              "\n          " + _vm._s(item) + "\n        "
                            ),
                          ])
                        }),
                  ],
                  2
                ),
          ]
        : _vm.noItemsText
        ? _c("div", [_c("em", [_vm._v(_vm._s(_vm.noItemsText))])])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }