var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VContainer", { staticClass: "pa-4" }, [
    _vm.currentFilters.length
      ? _c(
          "div",
          [
            _vm._l(_vm.currentFilters, function (filter, index) {
              return _c(
                "VChip",
                {
                  key: "catalog-filter-" + index,
                  staticClass: "ma-1",
                  attrs: { close: "" },
                  on: { input: filter.onclose },
                },
                [_vm._v("\n      " + _vm._s(filter.text) + "\n    ")]
              )
            }),
            _vm.currentFilters.length
              ? _c("ActionLink", {
                  staticClass: "mx-2",
                  attrs: { text: _vm.$tr("clearAll"), "data-test": "clear" },
                  on: { click: _vm.clearFilters },
                })
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }