var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "fieldset",
      { staticClass: "content-defaults mt-3 py-1" },
      [
        _c("legend", { staticClass: "font-weight-bold legend-title py-1" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.defaultsTitle || _vm.$tr("defaultsTitle")) +
              "\n    "
          ),
        ]),
        _c("p", { staticClass: "subtitle-1" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.defaultsSubTitle || _vm.$tr("defaultsSubTitle")) +
              "\n    "
          ),
        ]),
        _c("VTextField", {
          attrs: {
            box: "",
            maxlength: "200",
            counter: "",
            "data-name": "author",
            label: _vm.$tr("author"),
          },
          model: {
            value: _vm.author,
            callback: function ($$v) {
              _vm.author = $$v
            },
            expression: "author",
          },
        }),
        _c("VTextField", {
          attrs: {
            box: "",
            maxlength: "200",
            counter: "",
            "data-name": "provider",
            label: _vm.$tr("provider"),
          },
          model: {
            value: _vm.provider,
            callback: function ($$v) {
              _vm.provider = $$v
            },
            expression: "provider",
          },
        }),
        _c("VTextField", {
          attrs: {
            box: "",
            maxlength: "200",
            counter: "",
            "data-name": "aggregator",
            label: _vm.$tr("aggregator"),
          },
          model: {
            value: _vm.aggregator,
            callback: function ($$v) {
              _vm.aggregator = $$v
            },
            expression: "aggregator",
          },
        }),
        _c("VTextField", {
          attrs: {
            box: "",
            maxlength: "200",
            counter: "",
            "data-name": "copyrightHolder",
            label: _vm.$tr("copyrightHolder"),
          },
          model: {
            value: _vm.copyrightHolder,
            callback: function ($$v) {
              _vm.copyrightHolder = $$v
            },
            expression: "copyrightHolder",
          },
        }),
        _c("DropdownWrapper", {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var attach = ref.attach
                var menuProps = ref.menuProps
                return [
                  _c("VSelect", {
                    attrs: {
                      box: "",
                      "data-name": "license",
                      items: _vm.licenseOpts,
                      label: _vm.$tr("license"),
                      attach: attach,
                      menuProps: menuProps,
                    },
                    model: {
                      value: _vm.license,
                      callback: function ($$v) {
                        _vm.license = $$v
                      },
                      expression: "license",
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _vm.isCustomLicense
          ? _c("VTextarea", {
              attrs: {
                box: "",
                maxlength: "400",
                counter: "",
                "data-name": "licenseDescription",
                label: _vm.$tr("licenseDescription"),
                "auto-grow": "",
              },
              model: {
                value: _vm.licenseDescription,
                callback: function ($$v) {
                  _vm.licenseDescription = $$v
                },
                expression: "licenseDescription",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "fieldset",
      { staticClass: "content-defaults mt-3 py-1" },
      [
        _c("legend", { staticClass: "font-weight-bold legend-title py-1" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.thumbnailsSubTitle || _vm.$tr("thumbnailsTitle")) +
              "\n    "
          ),
        ]),
        _c("Checkbox", {
          staticClass: "mt-2",
          staticStyle: { "font-size": "16px" },
          attrs: {
            "data-name": "autoDeriveVideoThumbnail",
            label: _vm.$tr("videos"),
          },
          model: {
            value: _vm.autoDeriveVideoThumbnail,
            callback: function ($$v) {
              _vm.autoDeriveVideoThumbnail = $$v
            },
            expression: "autoDeriveVideoThumbnail",
          },
        }),
        _c("Checkbox", {
          staticClass: "mt-2",
          staticStyle: { "font-size": "16px" },
          attrs: {
            "data-name": "autoDeriveAudioThumbnail",
            label: _vm.translateConstant("audio"),
          },
          model: {
            value: _vm.autoDeriveAudioThumbnail,
            callback: function ($$v) {
              _vm.autoDeriveAudioThumbnail = $$v
            },
            expression: "autoDeriveAudioThumbnail",
          },
        }),
        _c("Checkbox", {
          staticClass: "mt-2",
          staticStyle: { "font-size": "16px" },
          attrs: {
            "data-name": "autoDeriveHtml5Thumbnail",
            label: _vm.$tr("html5"),
          },
          model: {
            value: _vm.autoDeriveHtml5Thumbnail,
            callback: function ($$v) {
              _vm.autoDeriveHtml5Thumbnail = $$v
            },
            expression: "autoDeriveHtml5Thumbnail",
          },
        }),
        _c("Checkbox", {
          staticClass: "mt-2",
          staticStyle: { "font-size": "16px" },
          attrs: {
            "data-name": "autoDeriveDocumentThumbnail",
            label: _vm.$tr("documents"),
          },
          model: {
            value: _vm.autoDeriveDocumentThumbnail,
            callback: function ($$v) {
              _vm.autoDeriveDocumentThumbnail = $$v
            },
            expression: "autoDeriveDocumentThumbnail",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }