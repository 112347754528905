var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VContainer",
    {
      staticClass: "py-1",
      class: _vm.$vuetify.breakpoint.xsOnly ? "px-0" : "px-4",
      attrs: { fluid: "" },
    },
    [
      _c("h1", { staticClass: "font-weight-bold mb-2 px-1 title" }, [
        _vm._v("\n    " + _vm._s(_vm.$tr("aboutHeader")) + "\n  "),
      ]),
      _c(
        "p",
        { staticClass: "px-2 subheading" },
        [
          _vm._v("\n    " + _vm._s(_vm.$tr("descriptionP1")) + "\n    "),
          _c("ActionLink", {
            attrs: { text: _vm.$tr("channelLink"), href: "#channel" },
            on: {
              click: function ($event) {
                _vm.aboutPanel = 1
              },
            },
          }),
        ],
        1
      ),
      _c("p", { staticClass: "px-2 subheading" }, [
        _vm._v("\n    " + _vm._s(_vm.$tr("descriptionP2")) + "\n  "),
      ]),
      _c("p", { staticClass: "mb-5 px-2 subheading" }, [
        _vm._v("\n    " + _vm._s(_vm.$tr("descriptionP3")) + "\n  "),
      ]),
      _c("h1", { staticClass: "font-weight-bold px-1 title" }, [
        _vm._v("\n    " + _vm._s(_vm.$tr("aboutLibraryHeader")) + "\n  "),
      ]),
      _c(
        "VExpansionPanel",
        { staticClass: "mb-5 mt-2", attrs: { expand: "" } },
        [
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("selectionQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("selectionAnswerP1")))]),
                    _c("p", [_vm._v(_vm._s(_vm.$tr("selectionAnswerP2")))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("maintenanceQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("maintenanceAnswerP1")))]),
                    _c(
                      "p",
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tr("maintenanceAnswerP2")) +
                            "\n            "
                        ),
                        _c("ActionLink", {
                          attrs: {
                            text: _vm.$tr("viewIntegrationGuide"),
                            target: "_blank",
                            href: "http://learningequality.org/r/integration-guide",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("endoresementQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("endorsementAnswer")))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("ownershipQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("ownershipAnswer")))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("newContentQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("newContentAnswer")))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("customContentQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("customContentAnswer")))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("h1", { staticClass: "font-weight-bold px-1 title" }, [
        _vm._v("\n    " + _vm._s(_vm.$tr("usingResourcesHeader")) + "\n  "),
      ]),
      _c(
        "VExpansionPanel",
        {
          staticClass: "mb-5 mt-2",
          attrs: { expand: "" },
          model: {
            value: _vm.aboutPanel,
            callback: function ($$v) {
              _vm.aboutPanel = $$v
            },
            expression: "aboutPanel",
          },
        },
        [
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("usingContentQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c(
                      "p",
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tr("usingContentAnswer")) +
                            "\n            "
                        ),
                        _c("ActionLink", {
                          attrs: {
                            text: _vm.$tr("viewGettingStartedLink"),
                            target: "_blank",
                            href: "https://learningequality.org/documentation/",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VExpansionPanelContent",
            {
              attrs: { id: "channel" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("channelQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("channelAnswer")))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("sampleContentQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("sampleContentAnswer")))]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(_vm._s(_vm.$tr("sampleContentAnswerItem1"))),
                      ]),
                      _c("li", [
                        _vm._v(_vm._s(_vm.$tr("sampleContentAnswerItem2"))),
                      ]),
                      _c(
                        "li",
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$tr("sampleContentAnswerItem3")) +
                              "\n              "
                          ),
                          _c("ActionLink", {
                            attrs: {
                              text: _vm.$tr("downloadKolibriLink"),
                              target: "_blank",
                              href: "https://learningequality.org/download",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("partialChannelQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("partialChannelAnswer")))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("coachContentQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("coachContentAnswer")))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("issueQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("issueAnswer")))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("h1", { staticClass: "font-weight-bold pl-1 title" }, [
        _vm._v("\n    " + _vm._s(_vm.$tr("aboutKolibriHeader")) + "\n  "),
      ]),
      _c(
        "VExpansionPanel",
        { staticClass: "mb-5 mt-2", attrs: { expand: "" } },
        [
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("KolibriQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("KolibriAnswer")))]),
                    _c("ul", [
                      _c("li", [_vm._v(_vm._s(_vm.$tr("KolibriAnswerItem1")))]),
                      _c("li", [_vm._v(_vm._s(_vm.$tr("KolibriAnswerItem2")))]),
                      _c("li", [_vm._v(_vm._s(_vm.$tr("KolibriAnswerItem3")))]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("usingKolibriQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("usingKolibriAnswerP1")))]),
                    _c("ul", [
                      _c(
                        "li",
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$tr("usingKolibriItem1")) +
                              "\n              "
                          ),
                          _c("ActionLink", {
                            attrs: {
                              target: "_blank",
                              text: _vm.$tr("visitWebsiteLink"),
                              href: "https://learningequality.org/kolibri/",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$tr("usingKolibriItem2")) +
                              "\n              "
                          ),
                          _c("ActionLink", {
                            attrs: {
                              target: "_blank",
                              text: _vm.$tr("viewDemoLink"),
                              href: "https://kolibri-demo.learningequality.org/en/user/#/signin",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$tr("usingKolibriItem3")) +
                              "\n              "
                          ),
                          _c("ActionLink", {
                            attrs: {
                              target: "_blank",
                              text: _vm.$tr("downloadLink"),
                              href: "https://learningequality.org/download/",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "p",
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tr("usingKolibriAnswerP2")) +
                            "\n            "
                        ),
                        _c("ActionLink", {
                          attrs: {
                            target: "_blank",
                            text: _vm.$tr("viewDocsLink"),
                            href: "https://learningequality.org/documentation/",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VExpansionPanelContent",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h2", { staticClass: "font-weight-bold subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("makerQuestion")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "VCard",
                [
                  _c("VCardText", [
                    _c("p", [_vm._v(_vm._s(_vm.$tr("makerAnswerP1")))]),
                    _c("p", [_vm._v(_vm._s(_vm.$tr("makerAnswerP2")))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }