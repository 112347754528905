var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.invalidFile
    ? _c(
        "span",
        { staticClass: "red--text", attrs: { "data-test": "error" } },
        [
          _vm.showSelectFile
            ? _c("ActionLink", {
                staticClass: "mr-2",
                attrs: { "data-test": "upload", text: _vm.$tr("selectFile") },
                on: {
                  click: function ($event) {
                    return _vm.$emit("open")
                  },
                },
              })
            : _vm._e(),
          _vm._v("\n  " + _vm._s(_vm.message) + "\n"),
        ],
        1
      )
    : _vm.uploading
    ? _c(
        "span",
        { staticClass: "grey--text", attrs: { "data-test": "progress" } },
        [_vm._v("\n  " + _vm._s(_vm.message) + "\n")]
      )
    : _vm.permanent
    ? _c(
        "span",
        { staticClass: "grey--text" },
        [
          _c("ActionLink", {
            staticClass: "mr-2",
            attrs: { text: _vm.$tr("selectFile") },
            on: {
              click: function ($event) {
                return _vm.$emit("open")
              },
            },
          }),
          _vm._v("\n  " + _vm._s(_vm.file.original_filename) + "\n"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }