var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasErrors
        ? _c(
            "VTooltip",
            {
              attrs: { top: "", lazy: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          "VIconWrapper",
                          _vm._g(
                            { attrs: { color: "red", large: _vm.large } },
                            on
                          ),
                          [_vm._v("\n        error\n      ")]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                681025814
              ),
            },
            [_c("span", [_vm._v(_vm._s(_vm.statusMessage(_vm.id)))])]
          )
        : _vm.progress >= 1
        ? _c(
            "VIconWrapper",
            {
              attrs: {
                large: _vm.large,
                color: "greenSuccess",
                "data-test": "done",
              },
            },
            [_vm._v("\n    check_circle\n  ")]
          )
        : _c("VProgressCircular", {
            attrs: {
              size: _vm.large ? 60 : 20,
              width: _vm.large ? 8 : 4,
              value: _vm.progress * 100,
              color: "greenSuccess",
              rotate: "270",
              "data-test": "progress",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }