var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "uploader",
      staticStyle: { border: "4px solid transparent" },
      style: {
        backgroundColor: _vm.highlightDropzone
          ? _vm.$vuetify.theme.primaryBackground
          : "transparent",
        borderColor: _vm.highlightDropzone
          ? _vm.$vuetify.theme.primary
          : _vm.borderColor,
        width: _vm.fill ? "100%" : "unset",
        height: _vm.fill ? "100%" : "unset",
      },
      attrs: { "data-test": "dropzone" },
      on: {
        dragenter: function ($event) {
          $event.preventDefault()
          return _vm.enter.apply(null, arguments)
        },
        dragover: function ($event) {
          $event.preventDefault()
          return _vm.over.apply(null, arguments)
        },
        dragleave: function ($event) {
          $event.preventDefault()
          return _vm.leave.apply(null, arguments)
        },
        drop: function ($event) {
          $event.preventDefault()
          return _vm.drop.apply(null, arguments)
        },
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }