var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VToolbar",
    _vm._b(
      {
        attrs: { flat: _vm.flat, color: _vm.color },
        scopedSlots: _vm._u(
          [
            {
              key: "extension",
              fn: function () {
                return [_vm._t("extension")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "VToolbar",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }