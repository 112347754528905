var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VCard",
        {
          staticClass: "channel my-3",
          class: { added: _vm.added },
          attrs: {
            hover: "",
            "data-test": "channel-card",
            tabindex: "0",
            href: _vm.linkToChannelTree ? _vm.channelHref : null,
            to: _vm.linkToChannelTree ? null : _vm.channelDetailsLink,
          },
          on: { click: _vm.goToChannelRoute },
        },
        [
          _c(
            "VLayout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "VFlex",
                {
                  staticClass: "pa-3",
                  class: {
                    xs12: _vm.fullWidth,
                    sm12: !_vm.fullWidth,
                    sm3: _vm.fullWidth,
                  },
                  attrs: { md3: "" },
                },
                [
                  _c("Thumbnail", {
                    attrs: {
                      src: _vm.channel.thumbnail_url,
                      encoding: _vm.channel.thumbnail_encoding,
                    },
                  }),
                ],
                1
              ),
              _c(
                "VFlex",
                {
                  class: {
                    xs12: _vm.fullWidth,
                    sm12: !_vm.fullWidth,
                    sm9: _vm.fullWidth,
                  },
                  attrs: { md9: "" },
                },
                [
                  _c(
                    "VCardTitle",
                    [
                      _c("VFlex", { attrs: { xs12: "" } }, [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "card-header font-weight-bold notranslate",
                            attrs: { dir: "auto" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.channel.name) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "VFlex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "VLayout",
                            { staticClass: "grey--text metadata-section" },
                            [
                              _c("span", { staticClass: "metadata-field" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$tr("resourceCount", {
                                        count: _vm.channel.count || 0,
                                      })
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _c("span", { staticClass: "metadata-field" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.language) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "VFlex",
                        { staticClass: "notranslate", attrs: { xs12: "" } },
                        [
                          _c("p", { attrs: { dir: "auto" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.channel.description) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VCardActions",
            [
              _c(
                "VLayout",
                { attrs: { "align-center": "", row: "", wrap: "" } },
                [
                  _c(
                    "VFlex",
                    { attrs: { shrink: "" } },
                    [
                      _vm.channel.published
                        ? _c("VCardText", { staticClass: "grey--text py-0" }, [
                            _vm.channel.last_published
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$tr("lastPublished", {
                                          last_published: _vm.$formatRelative(
                                            _vm.channel.last_published,
                                            { now: new Date() }
                                          ),
                                        })
                                      ) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _c("VCardText", { staticClass: "grey--text py-0" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tr("unpublishedText")) +
                                "\n          "
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "KTooltip",
                    {
                      attrs: {
                        reference: "lastUpdatedTime",
                        placement: "bottom",
                        refs: _vm.$refs,
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$tr("lastUpdated", {
                              updated: _vm.$formatRelative(
                                _vm.channel.modified,
                                { now: new Date() }
                              ),
                            })
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm.allowEdit && _vm.hasUnpublishedChanges
                    ? _c("Icon", {
                        ref: "lastUpdatedTime",
                        attrs: { icon: "unpublishedResource" },
                      })
                    : _vm._e(),
                  _c("VSpacer"),
                  _c(
                    "VFlex",
                    { attrs: { shrink: "" } },
                    [
                      !_vm.libraryMode
                        ? _c(
                            "KRouterLink",
                            { attrs: { to: _vm.channelDetailsLink } },
                            [
                              _c("KIconButton", {
                                staticClass: "mr-1",
                                attrs: {
                                  color: _vm.$themeTokens.primary,
                                  "data-test": "details-button",
                                  icon: "info",
                                  tooltip: _vm.$tr("details"),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.allowEdit && _vm.channel.published
                        ? _c("KIconButton", {
                            staticClass: "mr-1",
                            attrs: {
                              icon: "copy",
                              tooltip: _vm.$tr("copyToken"),
                              "data-test": "token-button",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                _vm.tokenDialog = true
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.loggedIn
                        ? _c("ChannelStar", {
                            staticClass: "mr-1",
                            attrs: {
                              channelId: _vm.channelId,
                              bookmark: _vm.channel.bookmark,
                            },
                          })
                        : _vm._e(),
                      _vm.showOptions
                        ? _c(
                            "Menu",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "VBtn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: "",
                                                flat: "",
                                                "data-test": "menu",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c("Icon", {
                                              attrs: {
                                                icon: "optionsVertical",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3730006063
                              ),
                            },
                            [
                              _c(
                                "VList",
                                [
                                  _vm.canEdit
                                    ? _c(
                                        "VListTile",
                                        {
                                          attrs: {
                                            to: _vm.channelEditLink,
                                            "data-test": "edit-channel",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "VListTileAvatar",
                                            [
                                              _c("KIconButton", {
                                                attrs: {
                                                  disabled: true,
                                                  icon: "edit",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("VListTileTitle", [
                                            _vm._v(
                                              _vm._s(_vm.$tr("editChannel"))
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.allowEdit && _vm.channel.published
                                    ? _c(
                                        "VListTile",
                                        {
                                          attrs: {
                                            "data-test": "token-listitem",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              _vm.tokenDialog = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "VListTileAvatar",
                                            [
                                              _c("KIconButton", {
                                                attrs: {
                                                  disabled: true,
                                                  icon: "copy",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("VListTileTitle", [
                                            _vm._v(
                                              _vm._s(_vm.$tr("copyToken"))
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.channel.source_url
                                    ? _c(
                                        "VListTile",
                                        {
                                          attrs: {
                                            href: _vm.channel.source_url,
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "VListTileAvatar",
                                            [
                                              _c("Icon", {
                                                attrs: { icon: "openNewTab" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("VListTileTitle", [
                                            _vm._v(
                                              _vm._s(_vm.$tr("goToWebsite"))
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.channel.demo_server_url
                                    ? _c(
                                        "VListTile",
                                        {
                                          attrs: {
                                            href: _vm.channel.demo_server_url,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _c(
                                            "VListTileAvatar",
                                            [
                                              _c("Icon", {
                                                attrs: { icon: "openNewTab" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("VListTileTitle", [
                                            _vm._v(
                                              _vm._s(_vm.$tr("viewContent"))
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.allowEdit
                                    ? _c(
                                        "VListTile",
                                        {
                                          attrs: {
                                            "data-test": "delete-channel",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              _vm.deleteDialog = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "VListTileAvatar",
                                            [
                                              _c("KIconButton", {
                                                attrs: {
                                                  disabled: true,
                                                  icon: "trash",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("VListTileTitle", [
                                            _vm._v(
                                              _vm._s(_vm.$tr("deleteChannel"))
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.deleteDialog
        ? _c(
            "KModal",
            {
              attrs: {
                title: _vm.$tr("deleteTitle"),
                submitText: _vm.$tr("deleteChannel"),
                cancelText: _vm.$tr("cancel"),
                "data-test": "delete-modal",
              },
              on: {
                submit: _vm.handleDelete,
                cancel: function ($event) {
                  _vm.deleteDialog = false
                },
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.$tr("deletePrompt")) + "\n  ")]
          )
        : _vm._e(),
      _vm.channel && _vm.channel.published
        ? _c("ChannelTokenModal", {
            attrs: { channel: _vm.channel },
            on: { copied: _vm.trackTokenCopy },
            model: {
              value: _vm.tokenDialog,
              callback: function ($$v) {
                _vm.tokenDialog = $$v
              },
              expression: "tokenDialog",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }