var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FullscreenModal",
    {
      attrs: { value: _vm.dialog, header: _vm.headerText },
      on: { input: _vm.onDialogInput },
      scopedSlots: _vm._u(
        [
          _vm.step === 1 && !_vm.isNew
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c("span", { staticClass: "notranslate" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ]),
                  ]
                },
                proxy: true,
              }
            : null,
          _vm.step === 2
            ? {
                key: "close",
                fn: function () {
                  return [
                    _c(
                      "VBtn",
                      {
                        staticClass: "rtl-flip",
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            _vm.step--
                          },
                        },
                      },
                      [
                        _c("Icon", {
                          attrs: {
                            icon: "back",
                            color: _vm.$themeTokens.textInverted,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
          {
            key: "bottom",
            fn: function () {
              return [
                _c("div", { staticClass: "mx-4 subheading" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$tr("channelSelectedCountText", {
                          channelCount: _vm.channels.length,
                        })
                      ) +
                      "\n    "
                  ),
                ]),
                _c("VSpacer"),
                _vm.step === 1
                  ? _c(
                      "VBtn",
                      {
                        attrs: { color: "primary", "data-test": "button-save" },
                        on: { click: _vm.save },
                      },
                      [_vm._v("\n      " + _vm._s(_vm.saveText) + "\n    ")]
                    )
                  : _c(
                      "VBtn",
                      {
                        attrs: {
                          color: "primary",
                          "data-test": "button-finish",
                        },
                        on: {
                          click: function ($event) {
                            _vm.step--
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n      " + _vm._s(_vm.$tr("finish")) + "\n    "
                        ),
                      ]
                    ),
              ]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _c(
        "VWindow",
        {
          model: {
            value: _vm.step,
            callback: function ($$v) {
              _vm.step = $$v
            },
            expression: "step",
          },
        },
        [
          _c(
            "VWindowItem",
            { attrs: { value: 1, "data-test": "collection-channels-view" } },
            [
              _c(
                "VContainer",
                { staticClass: "mx-0 pt-5" },
                [
                  _c(
                    "VLayout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "VFlex",
                        { attrs: { md12: "", lg10: "", xl8: "" } },
                        [
                          _c(
                            "VForm",
                            { ref: "channelsetform" },
                            [
                              _c("VTextField", {
                                attrs: {
                                  rules: _vm.nameRules,
                                  label: _vm.$tr("titleLabel"),
                                  maxlength: "200",
                                  counter: "",
                                  box: "",
                                  "data-test": "input-name",
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function ($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.channelSet.secret_token
                            ? _c(
                                "div",
                                [
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.$tr("tokenPrompt"))),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "caption grey--text text--darken-1",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$tr("token")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _c("CopyToken", {
                                    staticStyle: { "max-width": "max-content" },
                                    attrs: {
                                      token: _vm.channelSet.secret_token,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "h1",
                            {
                              staticClass:
                                "font-weight-bold headline mt-4 pt-4",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tr("channels")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          !_vm.loadingChannels
                            ? _c("p", { staticClass: "subheading" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$tr("channelCountText", {
                                        channelCount: _vm.channels.length,
                                      })
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm.loadingChannels
                            ? _c("VCardText", [_c("LoadingText")], 1)
                            : _c(
                                "div",
                                { attrs: { fluid: "" } },
                                [
                                  _c(
                                    "VBtn",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        color: "primary",
                                        "data-test": "button-select",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.step++
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$tr("selectChannelsHeader")
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._l(_vm.channels, function (channelId) {
                                    return _c(
                                      "VCard",
                                      { key: channelId, attrs: { flat: "" } },
                                      [
                                        _c(
                                          "ChannelItem",
                                          { attrs: { channelId: channelId } },
                                          [
                                            _c(
                                              "VBtn",
                                              {
                                                staticClass: "ma-0",
                                                attrs: {
                                                  flat: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeChannel(
                                                      channelId
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$tr("removeText")
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VWindowItem",
            {
              attrs: {
                value: 2,
                lazy: "",
                "data-test": "channels-selection-view",
              },
            },
            [
              _c(
                "VContainer",
                { staticClass: "mx-0 pt-5", attrs: { "fill-height": "" } },
                [
                  _c(
                    "VLayout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "VFlex",
                        { attrs: { md12: "", lg10: "", xl8: "" } },
                        [
                          _c(
                            "h1",
                            { staticClass: "font-weight-bold headline mb-2" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tr("selectChannelsHeader")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$tr("publishedChannelsOnlyText"))
                            ),
                          ]),
                          _c(
                            "VContainer",
                            { staticClass: "px-0" },
                            [
                              _c(
                                "Tabs",
                                {
                                  attrs: {
                                    showArrows: "",
                                    "slider-color": "primary",
                                  },
                                },
                                [
                                  _vm._l(_vm.lists, function (listType) {
                                    return _c("VTab", { key: listType.id }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.translateConstant(listType)
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  }),
                                  _vm._l(_vm.lists, function (listType) {
                                    return _c(
                                      "VTabItem",
                                      { key: listType.id, attrs: { lazy: "" } },
                                      [
                                        _c("ChannelSelectionList", {
                                          attrs: { listType: listType },
                                          model: {
                                            value: _vm.channels,
                                            callback: function ($$v) {
                                              _vm.channels = $$v
                                            },
                                            expression: "channels",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("MessageDialog", {
        attrs: {
          header: _vm.$tr("unsavedChangesHeader"),
          text: _vm.$tr("unsavedChangesText"),
          "data-test": "dialog-unsaved",
          "data-test-visible": _vm.showUnsavedDialog,
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _c("VSpacer"),
                _c(
                  "VBtn",
                  { attrs: { flat: "" }, on: { click: _vm.confirmCancel } },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$tr("closeButton")) + "\n      "
                    ),
                  ]
                ),
                _c(
                  "VBtn",
                  { attrs: { color: "primary" }, on: { click: _vm.save } },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$tr("saveButton")) + "\n      "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.showUnsavedDialog,
          callback: function ($$v) {
            _vm.showUnsavedDialog = $$v
          },
          expression: "showUnsavedDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }