var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.icon
    ? _c(
        "span",
        [
          _vm.showColor
            ? _c(
                "VChip",
                {
                  staticClass: "ma-0 pa-0",
                  class: {
                    iconOnly: !_vm.includeText,
                    fillWidth: _vm.fillWidth,
                  },
                  style: { width: _vm.fillWidth ? "100%" : "unset" },
                  attrs: {
                    label: "",
                    color: _vm.kind,
                    small: _vm.small,
                    textColor: _vm.fontColor,
                    "capture-as-image": "",
                  },
                },
                [
                  _c(
                    "VIconWrapper",
                    _vm._b(
                      { attrs: { small: "", color: _vm.fontColor } },
                      "VIconWrapper",
                      _vm.$attrs,
                      false
                    ),
                    [_vm._v("\n      " + _vm._s(_vm.icon) + "\n    ")]
                  ),
                  _vm.includeText
                    ? _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.text)),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "span",
                { attrs: { "capture-as-image": "" } },
                [
                  _c(
                    "VIconWrapper",
                    _vm._b(
                      { attrs: { color: _vm.fontColor } },
                      "VIconWrapper",
                      _vm.$attrs,
                      false
                    ),
                    [_vm._v("\n      " + _vm._s(_vm.icon) + "\n    ")]
                  ),
                  _vm.includeText
                    ? _c("span", [_vm._v(_vm._s(_vm.text))])
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }