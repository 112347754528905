var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { staticClass: "font-weight-bold mt-5 title" }, [
        _vm._v("\n    " + _vm._s(_vm.header) + "\n  "),
      ]),
      _c("VDataTable", {
        attrs: {
          "hide-headers": "",
          "hide-actions": "",
          items: _vm.tableUsers,
        },
        scopedSlots: _vm._u([
          {
            key: "no-data",
            fn: function () {
              return [
                _c("td", { staticClass: "px-0" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$tr("noUsersText")) + "\n      "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "items",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "tr",
                  { class: item.pending ? "grey--text" : "black--text" },
                  [
                    _c(
                      "td",
                      {
                        staticClass: "pl-1 subheading text-truncate",
                        staticStyle: { width: "350px", "max-width": "350px" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getUserText(item)) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c("td", { staticClass: "subheading" }, [
                      _vm._v(
                        "\n          " + _vm._s(item.email) + "\n        "
                      ),
                    ]),
                    _c("td", [
                      item.pending
                        ? _c(
                            "em",
                            { staticClass: "black--text font-weight-bold" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$tr("invitePendingText")) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "td",
                      { staticClass: "text-xs-right" },
                      [
                        item.id !== _vm.user.id &&
                        (item.pending || _vm.viewOnly)
                          ? _c(
                              "Menu",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "VBtn",
                                            _vm._g({ attrs: { flat: "" } }, on),
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$tr("optionsDropdown")
                                                  ) +
                                                  "\n                "
                                              ),
                                              _c("Icon", {
                                                staticClass: "ml-1",
                                                attrs: { icon: "dropdown" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "VList",
                                  [
                                    item.pending
                                      ? [
                                          _c(
                                            "VListTile",
                                            {
                                              attrs: { "data-test": "resend" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.resendInvitation(
                                                    item.email
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("VListTileTitle", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tr("resendInvitation")
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "VListTile",
                                            {
                                              attrs: { "data-test": "delete" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.selected = item
                                                  _vm.showDeleteInvitation = true
                                                },
                                              },
                                            },
                                            [
                                              _c("VListTileTitle", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tr("deleteInvitation")
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "VListTile",
                                            {
                                              attrs: {
                                                "data-test": "makeeditor",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.selected = item
                                                  _vm.showMakeEditor = true
                                                },
                                              },
                                            },
                                            [
                                              _c("VListTileTitle", [
                                                _vm._v(
                                                  _vm._s(_vm.$tr("makeEditor"))
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "VListTile",
                                            {
                                              attrs: {
                                                "data-test": "removeviewer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.selected = item
                                                  _vm.showRemoveViewer = true
                                                },
                                              },
                                            },
                                            [
                                              _c("VListTileTitle", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tr("removeViewer")
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("MessageDialog", {
        attrs: {
          header: _vm.$tr("removeViewerHeader"),
          text: _vm.$tr("removeViewerText", {
            first_name: _vm.selected.first_name,
            last_name: _vm.selected.last_name,
          }),
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function (ref) {
              var close = ref.close
              return [
                _c("VBtn", { attrs: { flat: "" }, on: { click: close } }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$tr("cancelButton")) + "\n      "
                  ),
                ]),
                _c(
                  "VBtn",
                  {
                    attrs: { color: "primary", "data-test": "confirm-remove" },
                    on: {
                      click: function ($event) {
                        return _vm.handleRemoveViewer(_vm.selected)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tr("removeViewerConfirm")) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.showRemoveViewer,
          callback: function ($$v) {
            _vm.showRemoveViewer = $$v
          },
          expression: "showRemoveViewer",
        },
      }),
      _c("MessageDialog", {
        attrs: {
          header: _vm.$tr("deleteInvitationHeader"),
          text: _vm.$tr("deleteInvitationText", { email: _vm.selected.email }),
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function (ref) {
              var close = ref.close
              return [
                _c("VBtn", { attrs: { flat: "" }, on: { click: close } }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$tr("cancelButton")) + "\n      "
                  ),
                ]),
                _c(
                  "VBtn",
                  {
                    attrs: { color: "primary", "data-test": "confirm-delete" },
                    on: {
                      click: function ($event) {
                        return _vm.handleDelete(_vm.selected.id)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tr("deleteInvitationConfirm")) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.showDeleteInvitation,
          callback: function ($$v) {
            _vm.showDeleteInvitation = $$v
          },
          expression: "showDeleteInvitation",
        },
      }),
      _c("MessageDialog", {
        attrs: {
          header: _vm.$tr("makeEditorHeader"),
          text: _vm.$tr("makeEditorText", {
            first_name: _vm.selected.first_name,
            last_name: _vm.selected.last_name,
          }),
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function (ref) {
              var close = ref.close
              return [
                _c("VBtn", { attrs: { flat: "" }, on: { click: close } }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$tr("cancelButton")) + "\n      "
                  ),
                ]),
                _c(
                  "VBtn",
                  {
                    attrs: {
                      color: "primary",
                      "data-test": "confirm-makeeditor",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.grantEditAccess(_vm.selected.id)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tr("makeEditorConfirm")) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.showMakeEditor,
          callback: function ($$v) {
            _vm.showMakeEditor = $$v
          },
          expression: "showMakeEditor",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }