var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DropdownWrapper", {
    attrs: { menuHeight: 270 },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var attach = ref.attach
          var menuProps = ref.menuProps
          return [
            _c(
              "VSelect",
              _vm._b(
                {
                  attrs: {
                    items: _vm.items,
                    multiple: "",
                    box: _vm.box,
                    clearable: "",
                    chips: "",
                    "no-data-text": _vm.$tr("noItemsFound"),
                    "menu-props": Object.assign({}, menuProps, { zIndex: 300 }),
                    attach: attach,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                    blur: _vm.resetScroll,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "selection",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _c(
                              "VChip",
                              { class: { notranslate: _vm.notranslate } },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.getText(item)) +
                                    "\n        "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _c(
                              "Checkbox",
                              {
                                ref: "checkbox-" + item.value,
                                staticClass: "scroll-margin",
                                attrs: { value: item.value },
                                model: {
                                  value: _vm.selections,
                                  callback: function ($$v) {
                                    _vm.selections = $$v
                                  },
                                  expression: "selections",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class: { notranslate: _vm.notranslate },
                                    style: _vm.getEllipsisStyle(),
                                    attrs: { dir: "auto" },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.getText(item)) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.selections,
                    callback: function ($$v) {
                      _vm.selections = $$v
                    },
                    expression: "selections",
                  },
                },
                "VSelect",
                _vm.$attrs,
                false
              )
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }