var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VLayout",
    {
      staticClass: "loading-text pa-4",
      style: { position: _vm.absolute ? "absolute" : "unset" },
      attrs: { "align-center": "", "justify-center": "", "fill-height": "" },
    },
    [
      _c(
        "VFlex",
        { attrs: { xs12: "" } },
        [
          _c("VProgressCircular", {
            attrs: { size: 70, width: 7, color: "loading", indeterminate: "" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }