import { render, staticRenderFns } from "./ChannelDetailsModal.vue?vue&type=template&id=396c24e6&scoped=true&"
import script from "./ChannelDetailsModal.vue?vue&type=script&lang=js&"
export * from "./ChannelDetailsModal.vue?vue&type=script&lang=js&"
import style0 from "./ChannelDetailsModal.vue?vue&type=style&index=0&id=396c24e6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "396c24e6",
  null
  
)

export default component.exports