var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MessageDialog",
    {
      attrs: { header: _vm.header, text: _vm.text },
      scopedSlots: _vm._u([
        {
          key: "buttons",
          fn: function (ref) {
            var close = ref.close
            return [
              _c("VSpacer"),
              _c(
                "VBtn",
                {
                  attrs: { depressed: "", color: "primary", "data-test": "ok" },
                  on: { click: close },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$tr("closeButtonLabel")) + "\n    "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _vm._t("default"),
      _vm.messageId
        ? _c("VCheckbox", {
            attrs: { color: "primary", label: _vm.$tr("dontShowAgain") },
            model: {
              value: _vm.dontShowAgain,
              callback: function ($$v) {
                _vm.dontShowAgain = $$v
              },
              expression: "dontShowAgain",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }