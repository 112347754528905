var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KModal",
    {
      staticClass: "error-detail-modal",
      attrs: {
        title: _vm.$tr("reportErrorHeader"),
        cancelText: _vm.$tr("closeAction"),
        size: "large",
      },
      on: {
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
      },
    },
    [
      _c(
        "section",
        [
          _vm.offline
            ? _c("h3", [
                _vm._v("\n      " + _vm._s(_vm.$tr("forumPrompt")) + "\n    "),
              ])
            : _vm._e(),
          _c("p", [_vm._v(_vm._s(_vm.$tr("forumUseTips")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("forumPostingTips")))]),
          _c("KExternalLink", {
            staticClass: "download-as-text-link",
            attrs: { text: _vm.forumLink, href: _vm.forumLink },
          }),
        ],
        1
      ),
      _vm.offline
        ? _c(
            "section",
            [
              _c("h3", [_vm._v(_vm._s(_vm.$tr("emailPrompt")))]),
              _c("p", [_vm._v(_vm._s(_vm.$tr("emailDescription")))]),
              _c("KExternalLink", {
                attrs: { text: _vm.emailAddress, href: _vm.emailAddressLink },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("h3", [
        _vm._v("\n    " + _vm._s(_vm.$tr("errorDetailsHeader")) + "\n  "),
      ]),
      _c("TechnicalTextBlock", { attrs: { text: _vm.error, maxHeight: 240 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }