var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VListTile",
        {
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "VListTileContent",
            [_c("VListTileTitle", [_vm._v(_vm._s(_vm.invitationText))])],
            1
          ),
          [
            _c(
              "VListTileAction",
              [
                _c(
                  "VTooltip",
                  {
                    attrs: { bottom: "", lazy: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "VBtn",
                              _vm._g(
                                {
                                  attrs: { icon: "", "data-test": "accept" },
                                  on: { click: _vm.accept },
                                },
                                on
                              ),
                              [_c("Icon", { attrs: { icon: "done" } })],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$tr("accept")))])]
                ),
              ],
              1
            ),
            _c(
              "VListTileAction",
              [
                _c(
                  "VTooltip",
                  {
                    attrs: { bottom: "", lazy: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "VBtn",
                              _vm._g(
                                {
                                  attrs: { icon: "", "data-test": "decline" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialog = true
                                    },
                                  },
                                },
                                on
                              ),
                              [
                                _c("Icon", {
                                  attrs: { color: "red", icon: "clear" },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$tr("decline")))])]
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
      _c("MessageDialog", {
        attrs: {
          header: _vm.$tr("decliningInvitation"),
          text: _vm.$tr("decliningInvitationMessage"),
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function (ref) {
              var close = ref.close
              return [
                _c("VSpacer"),
                _c("VBtn", { attrs: { flat: "" }, on: { click: close } }, [
                  _vm._v("\n        " + _vm._s(_vm.$tr("cancel")) + "\n      "),
                ]),
                _c(
                  "VBtn",
                  {
                    attrs: { "data-test": "decline-close", color: "primary" },
                    on: { click: _vm.declineAndClose },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$tr("decline")) + "\n      "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.dialog,
          callback: function ($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }