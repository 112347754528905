var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VDialog",
    _vm._b(
      {
        ref: "dialog",
        attrs: {
          value: _vm.value,
          app: "",
          fullscreen: "",
          scrollable: "",
          persistent: "",
          transition: "dialog-bottom-transition",
        },
      },
      "VDialog",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "VCard",
        { staticStyle: { "overflow-y": "auto" } },
        [
          _c(
            "VToolbar",
            {
              attrs: {
                color: _vm.color,
                dark: _vm.dark,
                fixed: "",
                "extension-height": 48,
                "clipped-left": "",
                "clipped-right": "",
              },
              scopedSlots: _vm._u(
                [
                  _vm.$slots.tabs
                    ? {
                        key: "extension",
                        fn: function () {
                          return [
                            _c(
                              "Tabs",
                              {
                                attrs: {
                                  color: _vm.color,
                                  "slider-color": "white",
                                  "align-with-title": "",
                                },
                              },
                              [_vm._t("tabs")],
                              2
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              _c(
                "VToolbarItems",
                [
                  _vm._t("close", function () {
                    return [
                      _c(
                        "VBtn",
                        {
                          attrs: {
                            flat: "",
                            icon: "",
                            exact: "",
                            "data-test": "close",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("input", false)
                            },
                          },
                        },
                        [
                          _c("Icon", {
                            attrs: {
                              icon: "clear",
                              color: _vm.$themeTokens.textInverted,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "VToolbarTitle",
                [
                  _vm._t("header", function () {
                    return [
                      _vm._v(
                        "\n          " + _vm._s(_vm.header) + "\n        "
                      ),
                    ]
                  }),
                ],
                2
              ),
              _c("VSpacer"),
              _vm._t("action"),
            ],
            2
          ),
          _c("OfflineText", {
            attrs: { toolbar: "", offset: _vm.topToolbarHeight },
          }),
          _c(
            "VContainer",
            {
              staticClass: "pa-0",
              style: "margin-top: " + _vm.contentOffset + "px;",
              attrs: { fluid: "" },
            },
            [_vm._t("default")],
            2
          ),
          _vm.$slots.bottom ? _c("BottomBar", [_vm._t("bottom")], 2) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }