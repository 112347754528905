var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppError", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _vm._v(
              "\n    " + _vm._s(_vm.$tr("channelNotFoundHeader")) + "\n  "
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "details",
        fn: function () {
          return [
            _vm._v(
              "\n    " + _vm._s(_vm.$tr("channelNotFoundDetails")) + "\n  "
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "VBtn",
              _vm._b(
                { attrs: { color: "primary" } },
                "VBtn",
                _vm.backHomeLink,
                false
              ),
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$tr("backToHomeAction")) + "\n    "
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }