var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formattedText,
          expression: "formattedText",
        },
      ],
      staticClass: "error-log",
      style: [
        _vm.dynamicHeightStyle,
        {
          backgroundColor: _vm.$themePalette.grey.v_50,
          border: _vm.$themePalette.grey.v_100,
        },
      ],
      attrs: { readonly: "", wrap: "soft", "aria-hidden": "true" },
      domProps: { value: _vm.formattedText },
      on: {
        input: function ($event) {
          if ($event.target.composing) {
            return
          }
          _vm.formattedText = $event.target.value
        },
      },
    }),
    _c("pre", { ref: "textBox", staticClass: "hidden-screen-only" }, [
      _vm._v(_vm._s(_vm.text)),
    ]),
    _c(
      "div",
      [
        _vm.clipboardAvailable
          ? _c("KButton", {
              ref: "copyButton",
              style: { marginTop: "8px", marginBottom: "8px" },
              attrs: {
                primary: false,
                text: _vm.$tr("copyToClipboardButtonPrompt"),
              },
              on: { click: _vm.copyError },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }