var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "underlay" },
    [
      _vm.channelList.length > 1
        ? _c("ChannelCatalogFrontPage", {
            ref: "frontpage",
            staticClass: "page",
            style: _vm.pageStyle,
            attrs: { channelList: _vm.channelList },
          })
        : _vm._e(),
      _vm._l(_vm.channelList, function (channelWithDetails) {
        return _c("Details", {
          key: channelWithDetails.id,
          ref: "details",
          refInFor: true,
          staticClass: "page",
          style: _vm.pageStyle,
          attrs: {
            details: channelWithDetails,
            printing: true,
            loading: false,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }