var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Icon", {
        ref: "tooltip",
        staticStyle: { "font-size": "20px" },
        attrs: { color: _vm.$themeTokens.primary, icon: _vm.icon },
      }),
      _c(
        "KTooltip",
        {
          attrs: {
            reference: "tooltip",
            placement: "bottom",
            maxWidth: "80%",
            refs: _vm.$refs,
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }