var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.channel
    ? _c(
        "VLayout",
        {
          staticClass: "pa-2",
          attrs: { "align-center": "", wrap: "" },
          on: { click: _vm.handleClick },
        },
        [
          _c(
            "VFlex",
            {
              staticClass: "pa-2",
              attrs: { xs12: "", sm4: "", md3: "", lg2: "" },
            },
            [_c("Thumbnail", { attrs: { src: _vm.channel.thumbnail_url } })],
            1
          ),
          _c(
            "VFlex",
            { attrs: { xs12: "", sm8: "", md9: "", lg10: "" } },
            [
              _c(
                "VLayout",
                { attrs: { "align-center": "" } },
                [
                  _c("VCardText", { staticClass: "py-0" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "card-header font-weight-bold notranslate",
                        attrs: { dir: "auto" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.channel.name) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      { staticClass: "grey--text metadata-section subheading" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$tr("versionText", {
                                version: _vm.channel.version,
                              })
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      { staticClass: "notranslate", attrs: { dir: "auto" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.channel.description) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
                  _vm._t("default"),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }