var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VContainer",
    { attrs: { fluid: "" } },
    [
      _c(
        "VLayout",
        { attrs: { row: "", wrap: "", "justify-center": "" } },
        [
          _c(
            "VFlex",
            { attrs: { xs12: "", sm10: "", md8: "", lg6: "" } },
            [
              _c(
                "VLayout",
                [
                  _c("VSpacer"),
                  _vm.isEditable && !_vm.loading
                    ? _c(
                        "VBtn",
                        {
                          staticClass: "add-channel-button",
                          attrs: {
                            color: "primary",
                            "data-test": "add-channel",
                          },
                          on: { click: _vm.newChannel },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$tr("channel")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "VLayout",
        { attrs: { row: "", wrap: "", "justify-center": "" } },
        [
          _c(
            "VFlex",
            { attrs: { xs12: "", sm10: "", md8: "", lg6: "" } },
            [
              _c(
                "VLayout",
                { attrs: { row: "", "justify-center": "" } },
                [
                  _c(
                    "VFlex",
                    { attrs: { xs12: "" } },
                    [
                      _vm.loading
                        ? _c("LoadingText")
                        : _vm.listChannels && !_vm.listChannels.length
                        ? _c("p", { staticClass: "headline mb-0" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tr("noChannelsFound")) +
                                "\n          "
                            ),
                          ])
                        : _vm._l(_vm.listChannels, function (channel) {
                            return _c("ChannelItem", {
                              key: channel.id,
                              attrs: {
                                channelId: channel.id,
                                allowEdit: "",
                                fullWidth: "",
                              },
                            })
                          }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }