var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { width: _vm.width + "px" } },
    [
      _c("Uploader", {
        attrs: {
          presetID: "channel_thumbnail",
          readonly: _vm.readonly,
          uploadingHandler: _vm.handleUploading,
          uploadCompleteHandler: _vm.handleUploadComplete,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var openFileDialog = ref.openFileDialog
              var handleFiles = ref.handleFiles
              return [
                _c(
                  "div",
                  { staticClass: "image-wrapper my-1" },
                  [
                    _vm.uploading || _vm.hasError
                      ? _c(
                          "div",
                          { staticStyle: { border: "4px solid transparent" } },
                          [
                            _c(
                              "VCard",
                              {
                                ref: "thumbnail",
                                staticClass: "thumbnail",
                                attrs: { "data-test": "loading" },
                              },
                              [
                                _c(
                                  "VLayout",
                                  {
                                    staticStyle: { "max-height": "0px" },
                                    attrs: {
                                      wrap: "",
                                      "align-center": "",
                                      "justify-center": "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-xs-center",
                                        staticStyle: { position: "absolute" },
                                      },
                                      [
                                        _c(
                                          "p",
                                          [
                                            _c("FileStatus", {
                                              attrs: {
                                                fileId: _vm.uploadingId,
                                                large: "",
                                                "data-test": "progress",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        !_vm.hasError
                                          ? _c("ActionLink", {
                                              attrs: {
                                                text: _vm.$tr("cancel"),
                                                "data-test": "cancel-upload",
                                              },
                                              on: {
                                                click: _vm.cancelPendingFile,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm.cropping
                      ? _c("croppa", {
                          style: { borderColor: _vm.$vuetify.theme.darkGrey },
                          attrs: {
                            "data-test": "cropper",
                            "zoom-speed": 10,
                            placeholder: _vm.$tr("upload"),
                            "disable-click-to-choose": true,
                            "disable-drag-and-drop": true,
                            width: _vm.width,
                            height: _vm.height,
                            "show-remove-button": false,
                            "initial-image": _vm.thumbnailSrc,
                            "initial-size": "contain",
                          },
                          on: { "new-image-drawn": _vm.cropperLoaded },
                          model: {
                            value: _vm.Cropper,
                            callback: function ($$v) {
                              _vm.Cropper = $$v
                            },
                            expression: "Cropper",
                          },
                        })
                      : _c(
                          "FileDropzone",
                          {
                            attrs: { disabled: _vm.readonly },
                            on: { dropped: handleFiles, click: openFileDialog },
                          },
                          [
                            _c("Thumbnail", {
                              attrs: {
                                src: _vm.thumbnailSrc,
                                encoding: _vm.displayEncoding,
                              },
                            }),
                          ],
                          1
                        ),
                  ],
                  1
                ),
                !_vm.readonly && (!_vm.uploading || _vm.hasError)
                  ? _c(
                      "VLayout",
                      {
                        attrs: {
                          "align-center": "",
                          row: "",
                          "data-test": "toolbar",
                        },
                      },
                      [
                        _vm.hasError
                          ? [
                              _c(
                                "span",
                                { staticClass: "body-1 red--text" },
                                [
                                  _c("FileStatusText", {
                                    attrs: { fileId: _vm.uploadingId },
                                    on: { open: openFileDialog },
                                  }),
                                ],
                                1
                              ),
                              _c("VSpacer"),
                              _c("IconButton", {
                                attrs: {
                                  icon: "clear",
                                  "data-test": "remove",
                                  text: _vm.$tr("cancel"),
                                },
                                on: { click: _vm.cancelPendingFile },
                              }),
                            ]
                          : _vm.cropping
                          ? [
                              _c("IconButton", {
                                attrs: {
                                  icon: "plus",
                                  "data-test": "zoomin",
                                  text: _vm.$tr("zoomIn"),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.Cropper && _vm.Cropper.zoomIn()
                                  },
                                  mousedown: _vm.cropZoomIn,
                                  mouseup: _vm.cropZoomStop,
                                },
                              }),
                              _c("IconButton", {
                                attrs: {
                                  icon: "minus",
                                  "data-test": "zoomout",
                                  text: _vm.$tr("zoomOut"),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.Cropper && _vm.Cropper.zoomOut()
                                  },
                                  mousedown: _vm.cropZoomOut,
                                  mouseup: _vm.cropZoomStop,
                                },
                              }),
                              _c("VSpacer"),
                              _c("ActionLink", {
                                staticClass: "mr-3",
                                attrs: {
                                  "data-test": "cancel",
                                  text: _vm.$tr("cancel"),
                                },
                                on: { click: _vm.cancelPendingFile },
                              }),
                              _c("ActionLink", {
                                attrs: {
                                  text: _vm.$tr("save"),
                                  "data-test": "save",
                                },
                                on: { click: _vm.save },
                              }),
                            ]
                          : [
                              _c("IconButton", {
                                attrs: {
                                  icon: "image",
                                  text: _vm.$tr("upload"),
                                },
                                on: { click: openFileDialog },
                              }),
                              _vm.hasThumbnail
                                ? _c("IconButton", {
                                    attrs: {
                                      icon: "crop",
                                      text: _vm.$tr("crop"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.cropping = true
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _c("VSpacer"),
                              _vm.hasThumbnail
                                ? _c("IconButton", {
                                    attrs: {
                                      icon: "clear",
                                      "data-test": "remove",
                                      text: _vm.$tr("remove"),
                                    },
                                    on: { click: _vm.remove },
                                  })
                                : _vm._e(),
                            ],
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }