var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FullscreenModal",
    {
      attrs: {
        value: _vm.dialog,
        header: _vm.isNew ? _vm.$tr("creatingHeader") : _vm.header,
      },
      on: { input: _vm.onDialogInput },
    },
    [
      !_vm.isNew
        ? _c(
            "ToolBar",
            { staticClass: "tabs", attrs: { color: "white" } },
            [
              _c(
                "Tabs",
                {
                  attrs: { "slider-color": "primary", height: "64px" },
                  model: {
                    value: _vm.currentTab,
                    callback: function ($$v) {
                      _vm.currentTab = $$v
                    },
                    expression: "currentTab",
                  },
                },
                [
                  _c(
                    "VTab",
                    {
                      staticClass: "px-3",
                      attrs: { href: "#edit", "data-test": "details-tab" },
                      on: {
                        click: function ($event) {
                          _vm.currentTab = "edit"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$tr("editTab")) + "\n      "
                      ),
                    ]
                  ),
                  _c(
                    "VTab",
                    {
                      staticClass: "px-3",
                      attrs: { href: "#share", "data-test": "share-tab" },
                      on: {
                        click: function ($event) {
                          _vm.currentTab = "share"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$tr("shareTab")) + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.loading
        ? _c("VProgressLinear", {
            staticStyle: { margin: "0" },
            attrs: { indeterminate: "", color: "loading", height: "5" },
          })
        : _vm._e(),
      _c(
        "VCardText",
        [
          _c(
            "VTabsItems",
            {
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            [
              _c(
                "VTabItem",
                { attrs: { value: "edit", "data-test": "edit-content" } },
                [
                  _c(
                    "Banner",
                    {
                      attrs: {
                        fluid: "",
                        value: _vm.isRicecooker,
                        color: "secondary lighten-1",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tr("APIText")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "VContainer",
                    {
                      staticClass: "mx-0",
                      class: { ricecooker: _vm.isRicecooker },
                    },
                    [
                      _c(
                        "VForm",
                        {
                          ref: "detailsform",
                          staticClass: "mb-5",
                          staticStyle: { "max-width": "960px" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.saveChannel.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("ChannelThumbnail", {
                            model: {
                              value: _vm.thumbnail,
                              callback: function ($$v) {
                                _vm.thumbnail = $$v
                              },
                              expression: "thumbnail",
                            },
                          }),
                          _c(
                            "fieldset",
                            { staticClass: "channel-info mt-3 py-1" },
                            [
                              _c(
                                "legend",
                                {
                                  staticClass:
                                    "font-weight-bold legend-title mb-2 py-1",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$tr("details")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _c("VTextField", {
                                attrs: {
                                  box: "",
                                  maxlength: "200",
                                  counter: "",
                                  label: _vm.$tr("channelName"),
                                  rules: _vm.nameRules,
                                  required: "",
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function ($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name",
                                },
                              }),
                              _c("LanguageDropdown", {
                                staticClass: "notranslate",
                                attrs: { box: "", required: "" },
                                model: {
                                  value: _vm.language,
                                  callback: function ($$v) {
                                    _vm.language = $$v
                                  },
                                  expression: "language",
                                },
                              }),
                              _c("VTextarea", {
                                attrs: {
                                  box: "",
                                  label: _vm.$tr("channelDescription"),
                                  maxlength: "400",
                                  rows: "4",
                                  "auto-grow": "",
                                  counter: "",
                                },
                                model: {
                                  value: _vm.description,
                                  callback: function ($$v) {
                                    _vm.description = $$v
                                  },
                                  expression: "description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("ContentDefaults", {
                            model: {
                              value: _vm.contentDefaults,
                              callback: function ($$v) {
                                _vm.contentDefaults = $$v
                              },
                              expression: "contentDefaults",
                            },
                          }),
                          _c(
                            "VBtn",
                            {
                              staticClass: "mt-5",
                              attrs: {
                                color: "primary",
                                type: "submit",
                                disabled: _vm.isDisable,
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.isNew
                                      ? _vm.$tr("createButton")
                                      : _vm.$tr("saveChangesButton")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "VTabItem",
                { attrs: { value: "share", "data-test": "share-content" } },
                [
                  _c(
                    "VCard",
                    { staticClass: "pa-5", attrs: { flat: "" } },
                    [
                      _c("ChannelSharing", {
                        attrs: { channelId: _vm.channelId },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("MessageDialog", {
        attrs: {
          header: _vm.$tr("unsavedChangesHeader"),
          text: _vm.$tr("unsavedChangesText"),
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function (ref) {
              var close = ref.close
              return [
                _c(
                  "VBtn",
                  { attrs: { flat: "" }, on: { click: _vm.confirmCancel } },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$tr("closeButton")) + "\n      "
                    ),
                  ]
                ),
                _c(
                  "VBtn",
                  { attrs: { color: "primary" }, on: { click: close } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tr("keepEditingButton")) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.showUnsavedDialog,
          callback: function ($$v) {
            _vm.showUnsavedDialog = $$v
          },
          expression: "showUnsavedDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }