var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("img", {
        attrs: {
          height: "99",
          width: "148",
          src: require("shared/images/le-logo.svg"),
        },
      }),
      _c("br"),
      _c("h1", { staticClass: "notranslate", attrs: { dir: "auto" } }, [
        _vm._v("\n    " + _vm._s(_vm.$tr("catalogHeader")) + "\n  "),
      ]),
      _c("DetailsRow", {
        attrs: { label: _vm.$tr("exported") },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$formatDate(Date.now(), {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    ) +
                    "\n    "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("DetailsRow", {
        attrs: { label: _vm.$tr("formatsHeading") },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("ExpandableList", {
                  attrs: {
                    noItemsText: _vm.$tr("defaultNoItemsText"),
                    items: _vm.kinds.map(_vm.translateConstant),
                    printing: true,
                    inline: "",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("DetailsRow", { attrs: { label: _vm.$tr("containsHeading") } }, [
        _vm.coachContent
          ? _c("span", [
              _vm._v("\n      " + _vm._s(_vm.$tr("coachHeading")) + "\n    "),
            ])
          : _vm._e(),
        _vm.exercises
          ? _c("span", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$tr("assessmentsIncludedText")) +
                  "\n    "
              ),
            ])
          : _vm._e(),
        _vm.subtitles
          ? _c("span", [
              _vm._v(
                "\n      " + _vm._s(_vm.$tr("subtitlesIncludedText")) + "\n    "
              ),
            ])
          : _vm._e(),
      ]),
      _c("DetailsRow", {
        attrs: { label: _vm.$tr("languagesHeading") },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("ExpandableList", {
                  attrs: {
                    noItemsText: _vm.$tr("defaultNoItemsText"),
                    items: _vm.languages,
                    printing: true,
                    inline: "",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("h2", { staticClass: "notranslate", attrs: { dir: "auto" } }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$tr("numberOfChannels", { num: _vm.channelList.length })
            ) +
            "\n  "
        ),
      ]),
      _vm._l(_vm.channelNames, function (channel, index) {
        return _c("div", { key: index, staticClass: "container" }, [
          _c("span", { staticClass: "px-2 text" }, [_vm._v(_vm._s(channel))]),
          _c("span", { staticClass: "px-2 right text" }, [
            _vm._v(_vm._s(index + 1)),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }