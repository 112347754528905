var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VContainer",
    { staticClass: "list-items", attrs: { fluid: "" } },
    [
      _c(
        "VLayout",
        { attrs: { row: "", wrap: "", "align-end": "", "justify-center": "" } },
        [
          _c(
            "VFlex",
            [
              _c("ActionLink", {
                staticClass: "mx-2",
                attrs: { text: _vm.$tr("aboutChannelSetsLink") },
                on: {
                  click: function ($event) {
                    _vm.infoDialog = true
                  },
                },
              }),
              _c(
                "MessageDialog",
                {
                  attrs: { header: _vm.$tr("aboutChannelSets") },
                  scopedSlots: _vm._u([
                    {
                      key: "buttons",
                      fn: function () {
                        return [
                          _c("VSpacer"),
                          _c(
                            "VBtn",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.infoDialog = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$tr("cancelButtonLabel")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.infoDialog,
                    callback: function ($$v) {
                      _vm.infoDialog = $$v
                    },
                    expression: "infoDialog",
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$tr("channelSetsDescriptionText")) +
                        "\n        "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$tr("channelSetsInstructionsText")) +
                        "\n        "
                    ),
                  ]),
                  _c("p", { staticClass: "red--text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$tr("channelSetsDisclaimer")) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("VSpacer"),
          _c(
            "VFlex",
            { staticClass: "text-xs-right" },
            [
              !_vm.loading
                ? _c(
                    "VBtn",
                    {
                      attrs: {
                        color: "primary",
                        "data-test": "add-channelset",
                      },
                      on: { click: _vm.newChannelSet },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$tr("addChannelSetTitle")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "VLayout",
        { staticClass: "pt-2", attrs: { row: "", "justify-center": "" } },
        [
          _c(
            "VFlex",
            { attrs: { xs12: "" } },
            [
              _vm.loading
                ? [_c("LoadingText")]
                : _vm.channelSets && !_vm.channelSets.length
                ? _c("p", { staticClass: "mb-0 text-xs-center" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tr("noChannelSetsFound")) +
                        "\n      "
                    ),
                  ])
                : [
                    _c("VDataTable", {
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.sortedChannelSets,
                        "hide-actions": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "items",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c("ChannelSetItem", {
                                attrs: { channelSetId: item.id },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }