var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "VCard",
        { staticStyle: { "min-height": "500px" }, attrs: { flat: "" } },
        [_c("LoadingText", { attrs: { absolute: "" } })],
        1
      )
    : _c(
        "div",
        [
          _c("h1", { staticClass: "font-weight-bold title" }, [
            _vm._v("\n    " + _vm._s(_vm.$tr("inviteSubheading")) + "\n  "),
          ]),
          _c(
            "VForm",
            {
              ref: "form",
              staticClass: "py-4",
              staticStyle: { "max-width": "600px" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitEmail.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "VLayout",
                { attrs: { row: "", "align-top": "" } },
                [
                  _c(
                    "VFlex",
                    { staticClass: "pr-2", attrs: { grow: "" } },
                    [
                      _c("VTextField", {
                        attrs: {
                          box: "",
                          color: "primary",
                          maxlength: "100",
                          counter: "",
                          label: _vm.$tr("emailLabel"),
                          "error-messages": _vm.error ? [_vm.error] : [],
                        },
                        on: {
                          input: function ($event) {
                            _vm.error = null
                          },
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("DropdownWrapper", {
                    attrs: { component: "VFlex", shrink: "", menuHeight: 120 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var attach = ref.attach
                          var menuProps = ref.menuProps
                          return [
                            _c("VSelect", {
                              staticStyle: { "max-width": "200px" },
                              attrs: {
                                box: "",
                                color: "primary",
                                "menu-props": "offsetY",
                                items: _vm.permissions,
                                "item-value": "id",
                                "item-text": "text",
                                "single-line": "",
                                "hide-details": "",
                                attach: attach,
                                menuProps: menuProps,
                              },
                              model: {
                                value: _vm.shareMode,
                                callback: function ($$v) {
                                  _vm.shareMode = $$v
                                },
                                expression: "shareMode",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "VBtn",
                {
                  attrs: {
                    color: "primary",
                    type: "submit",
                    disabled: _vm.sharing,
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$tr("inviteButton")) + "\n    "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._l(_vm.permissions, function (permission) {
            return _c("ChannelSharingTable", {
              key: "sharing-table-" + permission.id,
              attrs: { mode: permission.id, channelId: _vm.channelId },
            })
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }