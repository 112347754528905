var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VContainer",
    { staticClass: "pa-0 pb-5", attrs: { fluid: "" } },
    [
      _vm.loading
        ? _c("LoadingText", { staticClass: "pt-4" })
        : [
            _c("VTextField", {
              staticClass: "mt-4",
              staticStyle: { "max-width": "350px" },
              attrs: { box: "", label: _vm.$tr("searchText") },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
            !_vm.listChannels.length
              ? _c("p", { staticClass: "grey--text mb-0 mt-4" }, [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$tr("noChannelsFound")) + "\n    "
                  ),
                ])
              : _vm._l(_vm.listChannels, function (channel) {
                  return _c(
                    "VCard",
                    {
                      key: channel.id,
                      staticClass: "list-card-hover px-3",
                      attrs: { flat: "", hover: "" },
                    },
                    [
                      _c(
                        "VLayout",
                        { attrs: { "align-center": "", row: "" } },
                        [
                          _c("Checkbox", {
                            staticClass: "channel ma-0",
                            attrs: {
                              color: "primary",
                              "data-test": "checkbox-" + channel.id,
                              value: channel.id,
                            },
                            model: {
                              value: _vm.selectedChannels,
                              callback: function ($$v) {
                                _vm.selectedChannels = $$v
                              },
                              expression: "selectedChannels",
                            },
                          }),
                          _c("ChannelItem", {
                            attrs: {
                              channelId: channel.id,
                              "data-test": "channel-item-" + channel.id,
                            },
                            on: { click: _vm.handleSelectChannel },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }