var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VToolbar",
        {
          attrs: {
            app: "",
            "clipped-left": !_vm.$isRTL,
            "clipped-right": _vm.$isRTL,
            color: "appBar",
            height: "56",
            tabs: Boolean(_vm.$slots.tabs),
          },
          scopedSlots: _vm._u(
            [
              _vm.$slots.tabs
                ? {
                    key: "extension",
                    fn: function () {
                      return [
                        _c(
                          "Tabs",
                          { attrs: { "slider-color": "white" } },
                          [_vm._t("tabs")],
                          2
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm.loggedIn
            ? _c("VToolbarSideIcon", {
                on: {
                  click: function ($event) {
                    _vm.drawer = true
                  },
                },
              })
            : _c(
                "VToolbarSideIcon",
                {
                  staticClass: "ma-0",
                  staticStyle: { "border-radius": "8px" },
                  attrs: { href: _vm.homeLink, exact: "", color: "appBar" },
                },
                [
                  _c("KLogo", {
                    attrs: {
                      altText: "Kolibri Logo with background",
                      size: 36,
                    },
                  }),
                ],
                1
              ),
          _c("VToolbarTitle", [
            _vm._v(
              "\n      " + _vm._s(_vm.title || _vm.$tr("title")) + "\n    "
            ),
          ]),
          _c("VSpacer"),
          _c(
            "VToolbarItems",
            [
              _vm.loggedIn
                ? [
                    _c(
                      "Menu",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "VBtn",
                                    _vm._g(
                                      {
                                        staticStyle: {
                                          "text-transform": "none",
                                        },
                                        attrs: { flat: "" },
                                      },
                                      on
                                    ),
                                    [
                                      _c("KIconButton", {
                                        attrs: {
                                          disabled: true,
                                          icon: "person",
                                          color: "black",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "mx-2 subheading" },
                                        [_vm._v(_vm._s(_vm.user.first_name))]
                                      ),
                                      _c("KIconButton", {
                                        attrs: {
                                          disabled: true,
                                          icon: "dropdown",
                                          color: "black",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          564052194
                        ),
                      },
                      [
                        _c(
                          "VList",
                          [
                            _vm.user.is_admin
                              ? _c(
                                  "VListTile",
                                  { attrs: { href: _vm.administrationLink } },
                                  [
                                    _c(
                                      "VListTileAction",
                                      [
                                        _c("KIconButton", {
                                          attrs: {
                                            disabled: true,
                                            icon: "people",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("VListTileTitle", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$tr("administration")
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "VListTile",
                              { attrs: { href: _vm.settingsLink } },
                              [
                                _c(
                                  "VListTileAction",
                                  [
                                    _c("KIconButton", {
                                      attrs: {
                                        disabled: true,
                                        icon: "settings",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("VListTileTitle", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$tr("settings")),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "VListTile",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.showLanguageModal = true
                                  },
                                },
                              },
                              [
                                _c(
                                  "VListTileAction",
                                  [
                                    _c("KIconButton", {
                                      attrs: {
                                        disabled: true,
                                        icon: "language",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("VListTileTitle", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$tr("changeLanguage")
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "VListTile",
                              {
                                attrs: {
                                  href: "http://kolibri-studio.readthedocs.io/en/latest/index.html",
                                  target: "_blank",
                                },
                              },
                              [
                                _c(
                                  "VListTileAction",
                                  [
                                    _c("KIconButton", {
                                      attrs: {
                                        disabled: true,
                                        icon: "openNewTab",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("VListTileTitle", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$tr("help")),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "VListTile",
                              { on: { click: _vm.logout } },
                              [
                                _c(
                                  "VListTileAction",
                                  [
                                    _c("KIconButton", {
                                      attrs: { disabled: true, icon: "logout" },
                                    }),
                                  ],
                                  1
                                ),
                                _c("VListTileTitle", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$tr("logOut")),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "Menu",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "VBtn",
                                  _vm._g(
                                    {
                                      staticStyle: { "text-transform": "none" },
                                      attrs: { flat: "" },
                                    },
                                    on
                                  ),
                                  [
                                    _c("Icon", { attrs: { icon: "person" } }),
                                    _c("Icon", { attrs: { icon: "dropdown" } }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "VList",
                          [
                            _c(
                              "VListTile",
                              { attrs: { href: "/accounts/" } },
                              [
                                _c(
                                  "VListTileAction",
                                  [_c("Icon", { attrs: { icon: "login" } })],
                                  1
                                ),
                                _c("VListTileTitle", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$tr("logIn")),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "VListTile",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.showLanguageModal = true
                                  },
                                },
                              },
                              [
                                _c(
                                  "VListTileAction",
                                  [_c("Icon", { attrs: { icon: "language" } })],
                                  1
                                ),
                                _c("VListTileTitle", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$tr("changeLanguage")
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c("MainNavigationDrawer", {
        model: {
          value: _vm.drawer,
          callback: function ($$v) {
            _vm.drawer = $$v
          },
          expression: "drawer",
        },
      }),
      _vm.showLanguageModal
        ? _c("LanguageSwitcherModal", {
            style: { color: _vm.$themeTokens.text },
            on: {
              cancel: function ($event) {
                _vm.showLanguageModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }