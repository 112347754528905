var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.totalPages > 1
    ? _c("VPagination", {
        staticClass: "notranslate",
        attrs: { color: "primary", length: _vm.totalPages },
        model: {
          value: _vm.page,
          callback: function ($$v) {
            _vm.page = $$v
          },
          expression: "page",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }