var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { attrs: { to: _vm.channelSetDetailsLink } }, [
    _c("td", { staticClass: "notranslate", attrs: { dir: "auto" } }, [
      _vm._v("\n    " + _vm._s(_vm.channelSet.name) + "\n  "),
    ]),
    _c(
      "td",
      { staticStyle: { width: "224px" } },
      [
        _vm.channelSet.secret_token
          ? _c("CopyToken", { attrs: { token: _vm.channelSet.secret_token } })
          : _c("em", { staticClass: "grey--text" }, [
              _vm._v(_vm._s(_vm.$tr("saving"))),
            ]),
      ],
      1
    ),
    _c("td", { staticClass: "text-xs-right" }, [
      _vm._v("\n    " + _vm._s(_vm.$formatNumber(_vm.channelCount)) + "\n  "),
    ]),
    _c(
      "td",
      { staticClass: "text-xs-right" },
      [
        _c(
          "Menu",
          {
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    _c(
                      "VBtn",
                      _vm._g({ attrs: { flat: "", block: "" } }, on),
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("options")) +
                            "\n          "
                        ),
                        _c("Icon", { attrs: { icon: "dropdown" } }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          },
          [
            _c(
              "VList",
              [
                _c(
                  "VListTile",
                  {
                    attrs: {
                      "data-test": "edit",
                      to: _vm.channelSetDetailsLink,
                    },
                  },
                  [
                    _c(
                      "VListTileAction",
                      [_c("Icon", { attrs: { icon: "edit" } })],
                      1
                    ),
                    _c("VListTileTitle", [_vm._v(_vm._s(_vm.$tr("edit")))]),
                  ],
                  1
                ),
                _c(
                  "VListTile",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.deleteDialog = true
                      },
                    },
                  },
                  [
                    _c(
                      "VListTileAction",
                      [_c("Icon", { attrs: { icon: "trash" } })],
                      1
                    ),
                    _c("VListTileTitle", [_vm._v(_vm._s(_vm.$tr("delete")))]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("MessageDialog", {
          attrs: {
            header: _vm.$tr("deleteChannelSetTitle"),
            text: _vm.$tr("deleteChannelSetText"),
          },
          scopedSlots: _vm._u([
            {
              key: "buttons",
              fn: function (ref) {
                var close = ref.close
                return [
                  _c("VSpacer"),
                  _c(
                    "VBtn",
                    {
                      attrs: { flat: "", color: "primary" },
                      on: { click: close },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tr("cancel")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "VBtn",
                    {
                      attrs: { color: "primary", "data-test": "delete" },
                      on: {
                        click: function ($event) {
                          _vm.deleteChannelSet(_vm.channelSet)
                          close()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tr("deleteChannelSetTitle")) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.deleteDialog,
            callback: function ($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }