var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VApp",
    [
      _vm.libraryMode || _vm.isFAQPage
        ? _c(
            "VToolbar",
            {
              attrs: {
                color: "appBarDark",
                dark: "",
                "clipped-left": !_vm.$isRTL,
                "clipped-right": _vm.$isRTL,
                app: "",
              },
            },
            [
              _c(
                "VToolbarSideIcon",
                {
                  staticClass: "ma-0",
                  staticStyle: { "border-radius": "8px" },
                  attrs: { href: _vm.homeLink, exact: "", color: "appBarDark" },
                },
                [
                  _c("KLogo", {
                    attrs: {
                      altText: "Kolibri Logo with background",
                      showBackground: true,
                      size: 36,
                    },
                  }),
                ],
                1
              ),
              _c("VToolbarTitle", { staticClass: "notranslate" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.isFAQPage
                        ? _vm.$tr("frequentlyAskedQuestions")
                        : _vm.$tr("libraryTitle")
                    ) +
                    "\n    "
                ),
              ]),
            ],
            1
          )
        : _c("AppBar", {
            scopedSlots: _vm._u(
              [
                _vm.loggedIn
                  ? {
                      key: "tabs",
                      fn: function () {
                        return [
                          _vm._l(_vm.lists, function (listType) {
                            return _c(
                              "VTab",
                              {
                                key: listType.id,
                                attrs: { to: _vm.getChannelLink(listType) },
                                on: {
                                  click: function ($event) {
                                    return _vm.trackTabClick(listType)
                                  },
                                },
                              },
                              [
                                _c(
                                  "VBadge",
                                  {
                                    attrs: {
                                      value:
                                        _vm.invitationsByListCounts[listType],
                                      color: "black",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "badge",
                                          fn: function () {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$formatNumber(
                                                      _vm
                                                        .invitationsByListCounts[
                                                        listType
                                                      ]
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.translateConstant(listType))
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          _c(
                            "VTab",
                            {
                              attrs: { to: _vm.catalogLink },
                              on: { click: _vm.publicTabClick },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$tr("catalog")) +
                                  "\n      "
                              ),
                            ]
                          ),
                          _c(
                            "VTab",
                            {
                              attrs: { to: _vm.channelSetLink },
                              on: { click: _vm.channelSetsTabClick },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$tr("channelSets")) +
                                  "\n      "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
      _c(
        "VContent",
        [
          !_vm.isCatalogPage
            ? _c("OfflineText", {
                attrs: { toolbar: "", offset: _vm.toolbarHeight },
              })
            : _vm._e(),
          _c(
            "VContainer",
            {
              staticClass: "main-container pa-0",
              style:
                "height: calc(100vh - " +
                _vm.contentOffset +
                "px); margin-top: " +
                (_vm.offline ? 48 : 0) +
                "px;",
              attrs: { fluid: "" },
            },
            [
              _c(
                "VContainer",
                {
                  class: _vm.isCatalogPage ? "pa-0" : "pa-4",
                  attrs: { fluid: "" },
                },
                [
                  _c(
                    "VLayout",
                    { attrs: { row: "", wrap: "", "justify-center": "" } },
                    [
                      _c(
                        "VFlex",
                        { attrs: { xs12: "", sm10: "", md8: "", lg6: "" } },
                        [
                          _vm.invitationList.length
                            ? _c(
                                "VCard",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isChannelList,
                                      expression: "isChannelList",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "VList",
                                    { attrs: { subheader: "" } },
                                    [
                                      _c("VSubheader", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$tr("invitations", {
                                                count:
                                                  _vm.invitationList.length,
                                              })
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                      _vm._l(
                                        _vm.invitationList,
                                        function (invitation) {
                                          return _c("ChannelInvitation", {
                                            key: invitation.id,
                                            attrs: {
                                              invitationID: invitation.id,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.fullPageError
                    ? _c("ChannelListAppError", {
                        attrs: { error: _vm.fullPageError },
                      })
                    : _c("RouterView"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("GlobalSnackbar"),
      _c("PolicyModals"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }