var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.snackbarIsVisible
    ? _c(
        "VSnackbar",
        {
          key: _vm.key,
          attrs: {
            timeout: _vm.snackbarOptions.duration,
            left: "",
            value: _vm.snackbarIsVisible,
          },
          on: { input: _vm.visibilityToggled },
        },
        [
          _vm._v("\n  " + _vm._s(_vm.snackbarOptions.text) + "\n  "),
          _vm.snackbarOptions.actionText
            ? _c(
                "VBtn",
                {
                  attrs: { flat: "", text: "" },
                  on: { click: _vm.hideCallback },
                },
                [
                  _vm._v(
                    "\n    " + _vm._s(_vm.snackbarOptions.actionText) + "\n  "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }