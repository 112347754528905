var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppError", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _vm._v(
              "\n    " + _vm._s(_vm.$tr("permissionDeniedHeader")) + "\n  "
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "details",
        fn: function () {
          return [_vm._v("\n    " + _vm._s(_vm.details) + "\n  ")]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "ActionLink",
              _vm._b(
                { attrs: { text: _vm.$tr("goToHomePageAction") } },
                "ActionLink",
                _vm.backHomeLink,
                false
              )
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }