var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VNavigationDrawer",
        {
          staticStyle: { "z-index": "1000" },
          attrs: { fixed: "", temporary: "", right: _vm.$isRTL },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "VToolbar",
            { attrs: { color: _vm.color } },
            [
              _c(
                "VBtn",
                {
                  attrs: { flat: "", icon: "", tabindex: _vm.handleclickTab },
                  on: {
                    click: function ($event) {
                      _vm.drawer = false
                    },
                  },
                },
                [
                  _c("KIconButton", {
                    attrs: { icon: "clear", color: "black" },
                  }),
                ],
                1
              ),
              _c("VToolbarTitle", { staticClass: "notranslate" }, [
                _vm._v("\n        Kolibri Studio\n      "),
              ]),
            ],
            1
          ),
          _c(
            "VList",
            [
              _c(
                "VListTile",
                {
                  attrs: {
                    href: _vm.channelsLink,
                    tabindex: _vm.handleclickTab,
                  },
                },
                [
                  _c(
                    "VListTileAction",
                    [
                      _c("KIconButton", {
                        attrs: { disabled: true, icon: "home" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "VListTileContent",
                    { staticClass: "subheading" },
                    [
                      _c("VListTileTitle", [
                        _vm._v(_vm._s(_vm.$tr("channelsLink"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.user.is_admin
                ? _c(
                    "VListTile",
                    {
                      attrs: {
                        href: _vm.administrationLink,
                        tabindex: _vm.handleclickTab,
                      },
                    },
                    [
                      _c(
                        "VListTileAction",
                        [
                          _c("KIconButton", {
                            attrs: { disabled: true, icon: "people" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "VListTileContent",
                        { staticClass: "subheading" },
                        [
                          _c("VListTileTitle", [
                            _vm._v(_vm._s(_vm.$tr("administrationLink"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "VListTile",
                {
                  attrs: {
                    href: _vm.settingsLink,
                    tabindex: _vm.handleclickTab,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.trackClick("Settings")
                    },
                  },
                },
                [
                  _c(
                    "VListTileAction",
                    [
                      _c("KIconButton", {
                        attrs: { disabled: true, icon: "settings" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "VListTileContent",
                    { staticClass: "subheading" },
                    [
                      _c("VListTileTitle", [
                        _vm._v(_vm._s(_vm.$tr("settingsLink"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "VListTile",
                { on: { click: _vm.openLanguageModal } },
                [
                  _c(
                    "VListTileAction",
                    [
                      _c("KIconButton", {
                        attrs: { disabled: true, icon: "language" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "VListTileContent",
                    { staticClass: "subheading" },
                    [
                      _c("VListTileTitle", {
                        domProps: {
                          textContent: _vm._s(_vm.$tr("changeLanguage")),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "VListTile",
                {
                  attrs: {
                    href: _vm.helpLink,
                    tabindex: _vm.handleclickTab,
                    target: "_blank",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.trackClick("Help")
                    },
                  },
                },
                [
                  _c(
                    "VListTileAction",
                    [
                      _c("KIconButton", {
                        attrs: { disabled: true, icon: "openNewTab" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "VListTileContent",
                    { staticClass: "subheading" },
                    [
                      _c("VListTileTitle", [
                        _vm._v(_vm._s(_vm.$tr("helpLink"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "VListTile",
                { on: { click: _vm.logout } },
                [
                  _c(
                    "VListTileAction",
                    [
                      _c("KIconButton", {
                        attrs: { disabled: true, icon: "logout" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "VListTileContent",
                    { staticClass: "subheading" },
                    [
                      _c("VListTileTitle", [
                        _vm._v(_vm._s(_vm.$tr("logoutLink"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "VContainer",
            [
              _c("KLogo", {
                attrs: {
                  altText: "Kolibri logo",
                  showBackground: true,
                  size: 88,
                },
              }),
              _c("ActionLink", {
                attrs: {
                  text: _vm.$tr("copyright", {
                    year: new Date().getFullYear(),
                  }),
                  href: "https://learningequality.org/",
                  target: "_blank",
                  tabindex: _vm.handleclickTab,
                },
              }),
              _c(
                "p",
                { staticClass: "mt-4" },
                [
                  _c("ActionLink", {
                    attrs: {
                      href: "https://community.learningequality.org/c/support/studio",
                      target: "_blank",
                      text: _vm.$tr("giveFeedback"),
                      tabindex: _vm.handleclickTab,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showLanguageModal
        ? _c("LanguageSwitcherModal", {
            style: { color: _vm.$themeTokens.text },
            on: {
              cancel: function ($event) {
                _vm.showLanguageModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }