var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.offline && !_vm.libraryMode
    ? _c(
        "VFadeTransition",
        { attrs: { "data-test": "text" } },
        [
          _vm.indicator
            ? _c(
                "VTooltip",
                {
                  attrs: { bottom: "", "z-index": "300", lazy: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g({ staticClass: "px-4" }, on),
                              [
                                _c("Icon", {
                                  staticClass: "mx-2",
                                  attrs: { icon: "disconnected" },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(
                                          _vm.$tr("offlineIndicatorText")
                                        ) +
                                        "\n        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3082942763
                  ),
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.offlineText || _vm.$tr("offlineText"))),
                  ]),
                ]
              )
            : _vm.toolbar
            ? _c(
                "ToolBar",
                _vm._b(
                  {
                    style: "margin-top: " + _vm.offset + "px;",
                    attrs: {
                      color: "white",
                      dense: "",
                      flat: "",
                      fixed: "",
                      "clipped-left": "",
                      "clipped-right": "",
                    },
                  },
                  "ToolBar",
                  _vm.$attrs,
                  false
                ),
                [
                  _c("Icon", {
                    staticClass: "mx-3",
                    attrs: { icon: "disconnected" },
                  }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.offlineText || _vm.$tr("offlineText"))),
                  ]),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("Icon", {
                    staticClass: "mx-3",
                    attrs: { icon: "disconnected" },
                  }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.offlineText || _vm.$tr("offlineText"))),
                  ]),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }