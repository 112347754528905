var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DropdownWrapper", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var attach = ref.attach
          var menuProps = ref.menuProps
          return [
            _c(
              "VAutocomplete",
              _vm._b(
                {
                  staticClass: "language-dropdown",
                  attrs: {
                    box: "",
                    items: _vm.languages,
                    label: _vm.$tr("labelText"),
                    color: "primary",
                    itemValue: "id",
                    itemText: _vm.languageText,
                    autoSelectFirst: "",
                    allowOverflow: false,
                    clearable: "",
                    rules: _vm.rules,
                    required: _vm.required,
                    "no-data-text": _vm.$tr("noDataText"),
                    "search-input": _vm.input,
                    "menu-props": Object.assign({}, menuProps, {
                      maxWidth: 300,
                    }),
                    multiple: _vm.multiple,
                    chips: _vm.multiple,
                    attach: attach,
                  },
                  on: {
                    "update:searchInput": function ($event) {
                      _vm.input = $event
                    },
                    "update:search-input": function ($event) {
                      _vm.input = $event
                    },
                    change: function ($event) {
                      _vm.input = ""
                    },
                    focus: function ($event) {
                      return _vm.$emit("focus")
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _c(
                              "VTooltip",
                              {
                                attrs: { bottom: "", lazy: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "span",
                                            _vm._g(
                                              {
                                                staticClass: "text-truncate",
                                                attrs: { dir: "auto" },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                _vm._s(_vm.languageText(item))
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.languageText(item))),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.language,
                    callback: function ($$v) {
                      _vm.language = $$v
                    },
                    expression: "language",
                  },
                },
                "VAutocomplete",
                _vm.$attrs,
                false
              )
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }