var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VLayout",
    {
      staticClass: "error-v-layout",
      attrs: {
        "align-center": "",
        "justify-center": "",
        row: "",
        "fill-height": "",
        column: "",
      },
    },
    [
      _c("h1", [_vm._t("header")], 2),
      _c(
        "p",
        { staticClass: "details my-3 text-xs-center" },
        [_vm._t("details")],
        2
      ),
      _c("div", [_vm._t("actions")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }