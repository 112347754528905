var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.error.errorType === _vm.ErrorTypes.CHANNEL_NOT_FOUND
        ? _c("ChannelNotFoundError", { attrs: { backHomeLink: _vm.homeUrl } })
        : _vm.error.errorType === _vm.ErrorTypes.UNAUTHORIZED
        ? _c("PermissionsError", {
            attrs: {
              details: _vm.$tr("channelPermissionsErrorDetails"),
              backHomeLink: _vm.homeUrl,
            },
          })
        : _vm.error.errorType === _vm.ErrorTypes.PAGE_NOT_FOUND
        ? _c("PageNotFoundError", { attrs: { backHomeLink: _vm.homeUrl } })
        : _c("GenericError", {
            attrs: { error: _vm.error, backHomeLink: _vm.homeUrl },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }