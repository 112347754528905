var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AppError", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm.$tr("genericErrorHeader")) + "\n    "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "details",
            fn: function () {
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm.$tr("genericErrorDetails")) + "\n    "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c(
                  "VLayout",
                  { attrs: { column: "", "align-center": "" } },
                  [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "VBtn",
                          {
                            attrs: { color: "primary" },
                            on: { click: _vm.reloadPage },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tr("refreshAction")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _c(
                          "VBtn",
                          _vm._b({}, "VBtn", _vm.backHomeLink, false),
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tr("backToHomeAction")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("KButton", {
                      attrs: {
                        appearance: "basic-link",
                        text: _vm.$tr("helpByReportingAction"),
                      },
                      on: {
                        click: function ($event) {
                          _vm.showModal = true
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.showModal
        ? _c("ReportErrorModal", {
            attrs: { error: _vm.error.errorText },
            on: {
              cancel: function ($event) {
                _vm.showModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }