var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialog
    ? _c(
        "KModal",
        {
          attrs: { title: _vm.$tr("copyTitle"), cancelText: _vm.$tr("close") },
          on: {
            cancel: function ($event) {
              _vm.dialog = false
            },
          },
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.$tr("copyTokenInstructions")))]),
          _c("CopyToken", {
            attrs: { token: _vm.channel.primary_token },
            on: {
              copied: function ($event) {
                return _vm.$emit("copied")
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }