var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VDialog",
    _vm._b(
      {
        attrs: { width: "400" },
        model: {
          value: _vm.dialog,
          callback: function ($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog",
        },
      },
      "VDialog",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "VCard",
        { staticClass: "px-2 py-3" },
        [
          _c("VCardTitle", { staticClass: "pb-0" }, [
            _c("h1", { staticClass: "font-weight-bold title" }, [
              _vm._v("\n        " + _vm._s(_vm.header) + "\n      "),
            ]),
          ]),
          _c(
            "VCardText",
            { staticClass: "pb-4 pt-3", attrs: { "data-test": "text" } },
            [_c("p", [_vm._v(_vm._s(_vm.text))]), _vm._t("default")],
            2
          ),
          _c(
            "VCardActions",
            { attrs: { "data-test": "buttons" } },
            [_c("VSpacer"), _vm._t("buttons", null, { close: _vm.close })],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }